.ant-steps.ant-steps-label-vertical .ant-steps-item-content 
{
    width: 180px;
    margin-left: -30px;
}

.OrderSubmission_header-container__HxUsB .ant-steps.ant-steps-horizontal .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-tail::after,
.OrderSubmission_header-container__HxUsB .ant-steps.ant-steps-horizontal .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon,
.OrderSubmission_header-container__HxUsB .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot,
.OrderSubmission_header-container__HxUsB .ant-steps-item-active .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot 
{
     background: var(--themeColor);
}


.OrderSubmission_header-container__HxUsB .ant-steps .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon .ant-steps-finish-icon {
    color: #fff
}
