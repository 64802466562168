/* .container {
    width: 91%;
    margin: 2vh auto;
    padding-left: 3vw;
} */


.container {
    width: 74vw;
    margin: 13vh 0 0 5vw;
}

.containerSub {
    width: 73vw;
    margin: 0 0 0 1em;
}
  

.tagline {
    font-size: 12px;
    line-height: 28px;
}

.contentContainer {
    /* display: flex; */
    
}

/* .orderContainer {
    width: inherit;
} */

/* .orderCard {
    width: 100%;
} */

.orderTitle {
    font-size: 18px;
}

.orderCardInnerRow {
    width: inherit;
    margin-bottom: 20px;
}

.orderTable {
    width: 100%;
}

.orderData {
    font-size: 12px;
}

.SRCard {
    min-height: 40vh;
}

.SRCardTitle {
    font-size: 14px;
}

.h2hContainer {
    text-align: right;
    font-size: 14px;
}

/* .hubContainer {
    padding-left: 2vw;
} */

.hubCard {
    background-color: var(--lightGreyColor);
    min-height: 30vh;
    width: 100%;
}

.hubTitle {
    margin-bottom: 15px;
}

div .sender-receiver-content {
    font-size: 12px;
    margin-bottom: 12px;
    /* overflow-x: auto; */
}

.sender-receiver-content-1 {
    font-size: 12px;
}

.copyBTN {
    font-size: 10px;
    color: #4C3CFF;
    border: 1px;
    border-style: solid;
    border-color: #4C3CFF; 
}

.copyBTN:active {
    color: white;
    background-color: #4C3CFF;
}