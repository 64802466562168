.progressRow {
    justify-content: inherit;
    width: 100%;
    font-size: inherit;
}

.progressCol {
    /* padding-left: 2%; */
    padding-right: 3%;
    font-weight: 200;
    font-size: inherit;
}

.circleActive {
    color: red;
}

.circleInActive {
    color: var(--greyColor);
}