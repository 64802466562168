.app-frame {
    display: flex;
    flex-direction: row;
    overflow: auto;
    min-height: 100vh;
    background-color: white;
}

.app-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
}