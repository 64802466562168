.signup-bg {
  background-image: var(--page_ssoAccess_bg);
  background-size: cover;
  height: var(--page_ssoAccess_bg_height);
  width: var(--page_ssoAccess_bg_width);
}

.container {
  display: grid;
  height: 90vh;
}

.contextLayout {
  width: 100vw;
}

@media (max-width: 800px) {
    .signup-bg {
        display: none;
    }
    .container {
        height: 10vh;
    }
    .logo-mobile {
        margin: 10vh;
    }
}