.header-container,
.header-action-container, .header-action,
.banner, .banner-sub, .title, .price {
    display: flex;
}

.header-action-container, .title {
    justify-content: space-between;
}

.header-action, .banner {
    align-items: center;
}

.header-action, .price {
    gap: 13px;
}

.container {
    width: 75vw;
    padding: 12vh 0 0 6vw;
}
.header-container {
    justify-content: center;
    padding-bottom: 4.8em;
}
.header-steps {
    width: 50vw;
    color: var(--bs-success);
}
.step {
    /* font-size: 14px; */
    line-height: 20px;
}
.header-action-container {
    padding: 3vh 0;
}

.card {
    padding: 1em;
    margin: 2em 0;
}

.tagline {
    font-weight: 500;
    margin: 12px 0;
}

.banner {
    font-size: 13px;
    height: 4.5em;
    background-color: #f7f7f7;
    border-radius: 10px;
    z-index: 1;
    margin: 10px 0;
    position: relative;
}

.banner-trapezoid {
    width: 5em;
    padding: 0 6em 0 2em;
    border-radius: 10px 0 0 10px;
    height: inherit;
    border-right: 2.5em solid transparent;
    border-top: 4.5em solid var(--themeColor);
    color: #fff;
    z-index: 2;
}

.banner-trapezoid-sub {
    z-index: 999;
    position: absolute;
    top: 1.4em;
}

.banner-sub {
    gap: 3em;
    padding-left: 2em;
}


/* Order Submitted */
.OSContainer {
    /* justify-content: inherit; */
    text-align: center;
}

.icon {
    margin-bottom: 20px;
}

.strong {
    font-weight: bolder;
    font-size: 18px;
    margin-bottom: 10px;
}

.desc {
    width: 32vw;
    margin: 0 auto;
}

.ordid {
    color: var(--themeColor);
}