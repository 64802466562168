.navbar {
  width: 100%;
  height: 10vh;
  background-color: rgb(240, 136, 0, 1);
  display: flex;
  box-shadow: -3px 12px 7px 0px rgba(0,0,0,0.75);
  justify-content: space-between;
  align-items: center;
  padding: 0 5vw 0 15vw;
}

.tagline {
  font-size: 20px;
  /* right: 3em;
  margin-top: 3.5vh; */
  align-items: center;
  color: #fff;
  /* position: absolute; */

  cursor: pointer;
}

.tagline:hover, .tagline:focus {
  text-decoration: underline;
}

.icon-img {
  margin: auto;
}

.version {
  font-size: 14px;
  font-style: italic
}

@media (max-width: 1000px) {
    .navbar{
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10vw;
    }
    .tagline {
        color: #000;
        position: inherit;
    }
    .icon-img {
        margin: 0;
        height: 5vh;
        display: flex;
        align-items: center;
    }
}