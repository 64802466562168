:root {
  --font-small: 15px;
  --font-standard: 16px;
  --font-big: 18px;
  --font-bigger: 24px;
  --font-biggest: 36px;
}

.flex {
  display: flex;
  gap: 10px;
}
h2 {
  font-size: var(--font-big);
}
h3 {
  font-size: var(--font-standard);
}
.container {
  width: 75vw;
  margin: 15vh 0 0 5vw;
  display: flex;
  flex-direction: column;
  row-gap: 3em;
}
.myWallet {
  background: url(../../../../assets/images/topup/wallet-bg.png);
  background-size: cover;
  position: relative;
  height: 10rem;
}
.walletBg {
  position: absolute;
  height: 100%;
  right: -10px;
  top: 0;
}
.banner {
  display: grid;
  grid-template-columns: 40% 60%;
  justify-content: space-between;
  gap: 30px;
}
.formGroup {
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 6px 0;
}
.content {
  border: 1px solid #dedede;
}
.myWalletContent {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  padding: 0 10px;
}
.cardContent {
  font-size: var(--font-standard);
  line-height: 1.5;
  text-align: justify;
}
.title {
  position: relative;
  height: 50px;
}
.cardsFlex {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
}
.titleLabel {
  background-color: #f6f6f6;
  height: 55px;
}
.trapezoid {
  width: 150px;
  position: relative;
  z-index: 1;
}
.trapezoid:before {
  content: "";
  position: absolute;
  border-right: 25px solid transparent;
  border-top: 55px solid #f08800;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.popup {
  position: absolute;
  margin: -4% 0 0 33vw;
  background-color: var(--light-orange);
  border: 0.5px solid var(--medium-orange);
  color: var(--dark-orange);
  padding: 10px 22px;
  border-radius: 5px;
  z-index: 5;
}
