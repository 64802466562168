.inputForm {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--creamColor);
  font-size: 18px;
}

.inputContainer {
  width: 80%;
  /* border: 1px;
  border-style: solid;
  border-color: black; */
}

.inputContainer h4 {
  font-size: 24px;
}

.checkedIcon {
  color: var(--themeColor);
  padding-right: 2%;
}

.inputWrap {
  display: flex;
  flex-direction: column;
}

.inputWrap input,
.inputWrap label {
width: 100%;
}

.inputWrap input {
padding: 2% 3%;
}

.inputButton {
  font-size: 16px;
  width: 100%;
  height: 100%;
  padding: 2% 0;
  margin: 2% 0;
}

.invisibleBox {
  height: 48vh;
}

.progressRow {
  justify-content: center;
  font-size: 24px;
}


@media (max-width: 800px) {
    .inputForm {
        background-color: #fff;
    }
}