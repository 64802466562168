.container {
  width: 78vw;
  height: 95vh;
  margin-top: 4em;
}
.container-routes-content {
  padding: 0 4em;
  margin-top: 10vh;
}
.profile-container,
.form-action,
.right,
.toggle {
  display: flex;
}
.profile-container {
  flex-direction: column;
  row-gap: 5px;
  text-align: center;
}
.sidebar {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.43);
  height: 100%;
  padding: 10vh 1.5em;
}
.sidebar-nav {
  margin-top: 2em;
}

.sidebar-navlink {
  padding: 1.5em;
}


.profile-container,
.sidebar-navlink {
  align-items: center;
}

.container, 
.sidebar-navlink {
  display: grid;
  grid-template-columns: 30% 70%;
}
.sidebar-navlink:hover, 
.active {
  background-color: #fff6ea;
  color: #ff5f1f;
}

.reset-button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-size: 15px;
}
.form-action {
  border-bottom: 0.5px solid #c2c2c2;
  padding: 25px 0;
  margin-bottom: 15px;
}
.right,
.toggle {
  gap: 15px;
}
.right {
  margin-left: auto;
}
.toggle {
  padding-bottom: 30px;
}
/* .label {
  float: right;
} */