.container {
  width: 75vw;
  height: 90vh;
  padding-top: 13vh ;
  margin: 0 auto;
}
.container-main {
  display: grid;
  grid-template-columns: 60% 40%;
  column-gap: 0.5em;
  padding: 2em 0;
}
.container-submit {
  padding: 0 4em;
}
