
.container {
    width: 75vw;
    margin-top: 12vh;
    padding-left: 5vw;
}
  
.searchTransaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px
}

.search {
  width: 50%; 
  display: flex; 
  gap: 10px
}

.selectTransaction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px
}
  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 370px;
  }

.filterTransaction {
  margin-top: 3%
}

.cardWrap {
  box-shadow: 3px 3px 12px #00000033
}

.inputStyle {
  width: 170px; 
  height: 35px
}

.checkboxPoint {
  margin-left: 0; 
  margin-bottom: 5px;
  width: 150px;
}

.filterIcon {
  margin-left: -12px;
  margin-right: 12px;
}

.btnCancel {
  display: inline-block;
  width: 120px;
  height: 3em;
  padding: 15px;
  text-align: center;
  background-color: var(--lightGreyColor);
  color: gray;
  border-radius: 7px;
}

.selectSearch {
  height: 40px;
  line-height: 40px; 
  padding: 10px 0;
}

.table {
  margin-top: 10px;
  font-size: 14px;
  width: 100%;
  overflow-x: auto;
  overflow-y:auto;
  height:450px;
}

.StepProgression {
  position: relative;
  width: 100%;
  margin: 3em 0;
}

.cardInfo {
  width: 480px;
  height: 320px;
  padding-top: 1%;
  position: absolute;
  position: relative; 
  top: -50%;
left: 50%;
transform: translate(-50%);
z-index: 5;
}

.tableCard {
  margin: 5% 0;
  line-height: 25px;
}
.leftTable {
  margin-left: 5%;
}

.btnCard {
  width: 100%;
  text-align: center;
}



  
  
  