.card-content {
  display: grid;
  grid-template-columns: 70% 30%;
}
.card-icon-center {
  display: flex;
  margin: 0 auto;
  align-self: center;
}
.card-lines {
  text-align: left;
}
.card-bg {
  background-color: #fffaf3;
  margin-bottom: 15px;
}
.reset-button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-size: 15px;
  cursor: pointer;
}
.form-action {
  display: flex;
  border-bottom: 0.5px solid #c2c2c2;
  padding: 25px 0;
  margin-bottom: 15px;
}
.right {
  /* margin-left: auto; */
  display: flex;
  gap: 15px;
}
.statusTitle {
  font-size: 20px;
  font-weight: 700;
}
