.sidebar-logo-container {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.sidebar-logo {
    width: 80%;
}
