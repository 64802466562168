.container {
  width: 75vw;
  margin: 15vh 0 0 5vw;
}

.searchTransaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
}

.search {
  width: 50%;
  display: flex;
  gap: 10px;
}

.selectTransaction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 370px;
}

.filterTransaction {
  margin-top: 3%;
}

.cardWrap {
  box-shadow: 3px 3px 12px #00000033;
}

.inputStyle {
  width: 170px;
  height: 35px;
}

.checkboxPoint {
  margin-left: 0;
  margin-bottom: 5px;
  width: 150px;
}

.filterIcon {
  margin-left: -12px;
  margin-right: 12px;
}

.btnCancel {
  display: inline-block;
  width: 120px;
  height: 3em;
  padding: 15px;
  text-align: center;
  background-color: var(--lightGreyColor);
  color: gray;
  border-radius: 7px;
}

.selectSearch {
  height: 40px;
  line-height: 40px;
  padding: 10px 0;
}

.table {
  margin-top: 10px;
  font-size: 14px;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  height: 450px;
}
