.header-container {
    border-bottom: .2px solid;
    border-color: var(--greyColor);
    height: 10vh;
    padding: 0 50px !important;
    position:fixed;
    z-index: 5;
    width: 85%;
    background: white;
}
.header-content-right {
    margin-left: auto;
    column-gap: 20px;
}
.header-container,
.header-content-right,
.topup-container {
    display: flex;
    align-items: center;
}
.topup-container {
    background-color: aqua;
    padding: .8em;
    border-radius: 5px;
    width: 12em;
    justify-content: space-between;
}
.topup-container, 
.header-avatar {
    background-color: var(--lightDGreyColor);
}
.name, .id {
    font-size: 15px;
}
.name {
    font-weight: 600;
}
