.copyBTN {
    color: #4c3cff;
    border: 1px solid #4c3cff;
    padding: 4.5px 10px;
    border-radius: 50px;
    font-size: 12px;
    display: flex;
    justify-content: center;
}

.copyBTN:active {
    color: white;
    background-color: #4c3cff;
}
