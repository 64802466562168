.content-container {
  padding: 25px;
}
.buttons {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 5px;
  margin: 20px auto;
  width: 60%;
}
.button {
  box-sizing: border-box;
  padding: 10px;
  background-color: transparent;
  border: 1px solid #eaeaea;
  color: #9d9d9d;
}
.active {
  color: white;
  background-color: #f08800;
  border: none;
}
.grey-label {
  margin: 15px 0;
  color: #9d9d9d;
}
.label-title {
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
}
