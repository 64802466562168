.logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

.version {
    width: 100%;
    text-align: center;
    color: white;
    position: absolute;
    bottom: 15px;
}