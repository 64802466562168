.content-container {
  padding: 25px 40px;
  text-align: center;
}
.contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0;
}
.buttons {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 5px;
}
.button {
  box-sizing: border-box;
  padding: 10px;
  background-color: transparent;
  border: 1px solid #eaeaea;
  color: #9d9d9d;
  cursor: pointer;
}
.active {
  color: white;
  background-color: #f08800;
  border: none;
}
.middle {
  color: #f08800;
  background-color: transparent;
  border: 1px solid #f08800;
}
