.inputForm {
    background-color: var(--creamColor);
    width: 100%;
    height: 100%;
    padding: 10%;
    display: grid;
}

.inputLabel,
.inputButton,
.tagline p
 {
  font-size: 16px;
}

.tagline h4 {
    font-size: 24px;
}

.progressRow,
.inputWrap {
    display: flex;
}

.progressRow {
    font-size: 30px;
    width: 28%;
    justify-content: space-evenly;
}

.inputWrap {
    height: 50vh;
    flex-direction: column;
    justify-content: flex-end;
}

.footer, 
.progressRow {
    margin: 0 auto;
    text-align: center;
}

.transparentBTN {
    background-color: transparent;
    border: none;
}

.backtoLOGIN {
    text-align: center;
    margin-bottom: 20px;
}

.inputButton {
    width: 100%;
    padding: 15px 15px 40px 15px;
    margin-top: 20%;
}

.email, .link {
    display: inline-block;
    color: var(--themeColor);

}

.forgotPassword {
    display: flex;
    justify-content: space-between;
    width: 65vh;
}


@media (max-width: 1000px) {
    .inputForm {
        background-color: #fff;
    }
    .logo-mobile {
        margin-right: 50vw;
    }

    .logo-mobile,
    .progressRow {
        padding-bottom: 30px;
    }

    .inputWrap {
        height: 40vh;
    }

    .progressRow {
        order: 1;
        height: 20vh;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-evenly;
        gap: 30vw;
        font-size: 30px;
    }
    .tagline {
        order: 2;
    }
    .inputWrap {
        order: 3;
    }
    .footer {
        order: 4;
    }
}