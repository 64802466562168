.card-location {
    border: 0.5px solid #efefef;
    display: grid;
    grid-template-columns: 40% 60%;
}
.card-location-detail {
    position: relative;
    font-size: 12px;
    padding: 1.5em 3em;
}
.card-location-content {
    margin: 8px 0;
    line-height: 1.4em;
}
.hub-copy {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.label-title {
    font-size: 17px;
    margin: 15px 0;
}

.num, 
.label-title {
  font-weight: 700;
}
.btnSubmit {
    width: 100%;
    height: 40px;
}
.container-submit-content {
    text-align: center;
}
.card-content {
  display: grid;
  grid-template-columns: 30% 70%;
}
.card-icon-center {
  display: flex;
  margin: 0 auto;
  align-self: center;
}
.card-bg {
  background-color: #fffaf3;
  margin-bottom: 15px;
}
.container-submit-content {
  font-size: 14px;
  color: #b8b8b8;
  margin: 30px 0 20px;
}
.num {
  font-size: 24px;
  margin-bottom: 5px;
}
